/*main body stying*/
body {
    background: black;
    color: #fae7c9;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;
}

header {
    background: black;
    padding: 20px 40px;
    text-align: center;
}

section {
    background: #fae7c9;
    color: black;
    padding: 0px;
    display: flex;
    flex-direction: row;
}

div {
    margin: auto;
    width: auto;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

li {
    display: inline-block;
    margin: 20px;
}

p {
    margin: 20px;
    padding: 20;
}

.interests {
    flex-wrap: wrap;
}

.interests div {
    width: 250px;
    padding: 20px;
    border: rgb(58, 58, 58);
}

.interests figure img {
    padding: 20px;
    height: 125px;
    width: auto;
}

.interests h1 {
    font-size: 20pt;
}

.interest p {
    padding: 20px;
    font-size: 16pt;
}

/*About page*/

.profile-pic {
    border-radius: 50%;
    width: 200px;
}

.contact-links {
    flex-wrap: wrap;
}

.contact-links div {
    width: 150px;
    padding: 20px;
    border: rgb(58, 58, 58);
}

.contact-links div figure img {
    height: 80px;
    width: auto;
}

/*footer*/

footer {
    background: black;
    color: #fae7c9;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px 20px;
    font-size: small;
}

footer a {
    text-decoration: none;
}
